import { useGetUser } from '@db/collections';
import { NewBoxButton, Spacer } from 'design-system';
import Image from 'next/image';
import Link from 'next/link';

export const HomeTrainingReport = () => {
  const { user } = useGetUser();
  if (!user) return null;

  const trainingOrder = user.orders.find(
    (order) =>
      order.receipt.lessonCode.includes('VISIT_TRAINING') &&
      order.visitTrainingReceipt &&
      order.visitTrainingReceipt.lessons.some(
        (lesson) => lesson.state === 'complete' && lesson.drivingList,
      ),
  );

  if (!trainingOrder) return null;

  return (
    <>
      <Spacer className="h-[10px]" />
      <div className="mx-16 flex flex-col items-center gap-32 rounded-[24px] bg-white px-20 py-32">
        <p className="text-new-Sub-Title">연수 분석 리포트가 도착했어요!</p>
        <Image src="/training/training-pencil.png" width={145} height={81} alt="연수분석리포트" />
        <Link href={`/profile/training/report?orderId=${trainingOrder.id}`} className="w-full">
          <NewBoxButton label="분석 리포트 보러가기" styles="filled-yellow" fill />
        </Link>
      </div>
    </>
  );
};
