import { useEffect } from 'react';

import type { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { Home } from '@templates/Home/Home';

import { useGetLocation } from '@hooks';
import { isAppApproaching } from '@utils/Common/is-app-approaching';

import type { UserInfo } from './personal/funnel';

const Footer = dynamic(() => import('@components/Footer').then((v) => ({ default: v.Footer })));

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const userAgent = req.headers['user-agent'];
  const isAppUser = userAgent?.includes('WISHROOM');

  if (!isAppUser) {
    return {
      props: {},
    };
  }

  const getUserInfoFromCookies = (cookies: string | undefined): UserInfo | null => {
    if (!cookies) return null;

    const userInfoCookie = cookies
      .split('; ')
      .find((cookie) => cookie.startsWith('user-info='))
      ?.split('=')[1];

    if (userInfoCookie) {
      try {
        return JSON.parse(decodeURIComponent(userInfoCookie));
      } catch (error) {
        console.error('Error parsing user-info cookie:', error);
      }
    }

    return null;
  };

  const parsedUserInfo = getUserInfoFromCookies(req.headers.cookie);

  if (parsedUserInfo) {
    return {
      props: {
        userInfo: parsedUserInfo,
      },
    };
  }

  return {
    redirect: {
      destination: '/personal/funnel',
      permanent: false,
    },
    props: { userInfo: null },
  };
};

const isApp = isAppApproaching();

export default function HomePage() {
  const router = useRouter();
  useGetLocation({
    isSilent: false,
  });

  useEffect(() => {
    if (!isApp) {
      return;
    }

    const userInfo = localStorage.getItem('user-info');

    if (!userInfo) {
      router.push('/personal/funnel');
    }
  }, [router]);

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'Organization',
              name: '운전선생 : 전국 운전면허학원 최저가 예약',
              url: 'https://drivingteacher.co.kr',
              sameAs: [
                'https://www.instagram.com/drivingteacher_official',
                'https://www.youtube.com/@운전선생',
                'https://blog.naver.com/drivingteacher',
                'https://play.google.com/store/apps/details?id=com.drivingteacher',
                'https://itunes.apple.com/kr/app/%EC%9A%B4%EC%A0%84%EC%84%A0%EC%83%9D-%EC%9A%B4%EC%A0%84%ED%95%99%EC%9B%90-%EC%B5%9C%EC%A0%80%EA%B0%80-%EC%98%88%EC%95%BD/id6444027313',
              ],
            }),
          }}
        />
      </Head>
      <Home />
      <Footer />
    </>
  );
}
