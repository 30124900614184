import { useEffect } from 'react';

import { LogEvent } from '@analytics/LogEvent/LogEvent';
import { isWebviewReadyAtom } from '@jotaiStore/config';
import { useAtomValue } from 'jotai';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { useWindowSize } from '@hooks/use-window-size';

import { HomeTrainingReport } from './components/HomeTrainingReport';

const HomeBanner = dynamic(() =>
  import('./components/HomeBanner/HomeBanner').then((m) => m.HomeBanner),
);
const HomeCTA = dynamic(() => import('./components/HomeCTA/HomeCTA').then((m) => m.HomeCTA));
const HomeEventBanner = dynamic(() =>
  import('./components/HomeEventBanner/HomeEventBanner').then((m) => m.HomeEventBanner),
);
const HomeGuide = dynamic(() =>
  import('./components/HomeGuide/HomeGuide').then((m) => m.HomeGuide),
);

const HomeAdvertisement = dynamic(() => import('./components/HomeAdvertisement/HomeAdvertisement'));
const Spacer = dynamic(() => import('design-system').then((m) => m.Spacer));

export const Home = () => {
  const { height: innerHeight } = useWindowSize();
  const isWebviewReady = useAtomValue(isWebviewReadyAtom);
  const router = useRouter();

  useEffect(() => {
    router.prefetch('/explore');
  }, []);

  useEffect(() => {
    if (!isWebviewReady) return;
    LogEvent.유입.homeView();
  }, [isWebviewReady]);

  return (
    <div style={{ minHeight: innerHeight }} className="bg-gray-150">
      <div className="bg-new-white">
        <HomeEventBanner />
      </div>
      <div className="bg-new-gray-100 relative mt-[-24px] rounded-t-[16px]">
        <HomeCTA />
        <HomeAdvertisement />
        <HomeTrainingReport />
        <Spacer className="h-[24px]" />
        <HomeGuide />
        <Spacer className="h-[24px]" />
        <div className="px-16">
          <HomeBanner backgroundColor="white" indicator="dot" />
        </div>
      </div>
    </div>
  );
};
